<template>
    <div class="container-fluid my-4">
        <component-loader v-if="amlSessionLoading"/>
        <div v-else>
            <div class="mb-2">
                <h1 class="mb-0">{{ $t('amlSessions.detail.heading') }} {{ amlSessionId }}</h1>
                <span :class="{'badge text-white bg-primary': true, 'bg-danger': sessionHasError}">
                    {{ $t(`clientSessions.state.${sessionState}`) }}
                </span>
            </div>
            <b-btn
                class="mb-3"
                variant="secondary"
                size="sm"
                @click.prevent="onBack"
            >
                <b-icon icon="chevron-double-left" /> {{ $t('app.goBack') }}
            </b-btn>
            <div class="row">
                <div class="col-12 col-xl-7 d-flex align-items-center">
                    <b-btn
                        class="mb-2 mb-xl-0"
                        variant="primary"
                        size="sm"
                        @click.prevent="onSendToIvan"
                    >
                        <b-icon class="mr-2" icon="envelope" />{{ $t('clientSessions.sendToIvan') }}
                    </b-btn>
                </div>
                <div class="col-12 col-xl-5">
                    <loader-overlay
                        :loading="savingState"
                    >
                        <session-change-state-form
                            :current-state="sessionState || 'error'"
                            @submit="onChangeSessionState"
                        />
                    </loader-overlay>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-7 mb-4">
                    <div class="bg-white shadow p-4 rounded">
                        <div class="mb-4" v-if="amlSession && amlSession.errorMessage">
                            <div class="alert alert-danger">
                                {{ amlSession.errorMessage }}
                            </div>
                        </div>
                        <b-tabs content-class="mt-4" fill>
                            <b-tab
                                :title="$t('clientSessions.detail.tab.personalData')"
                            >
                                <div class="row mt-4">
                                    <div class="col-12 col-lg-7 mb-4 d-flex flex-column">
                                        <basic-data-client-info
                                            class="rounded border p-4 h-100"
                                            :basic-data="basicData || {}"
                                            :client-type="clientType"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-5 mb-4 d-flex flex-column">
                                        <basic-data-contact-info
                                            class="rounded border p-4 h-100"
                                            :basic-data="basicData || {}"
                                        />
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab :title="$t('clientSessions.detail.tab.legalInfo')">
                                <div class="row mt-4">
                                    <div class="col-12 col-lg-6 mb-4 d-flex flex-column">
                                        <legal-info-money-origin
                                            class="border rounded p-4 h-100"
                                            :legal-info="legalInfo || {}"
                                            :uploads="uploads || []"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-6 mb-4 d-flex flex-column">
                                        <legal-info-reason-and-amount
                                            class="border rounded p-4 h-100"
                                            :uploads="uploads || []"
                                            :legal-info="legalInfo || {}"
                                        />
                                    </div>
                                    <div
                                        class="col-12 mb-4 d-flex flex-column"
                                        v-if="clientType === 'PO'"
                                    >
                                        <legal-info-company-info
                                            class="border rounded p-4 h-100"
                                            :legal-info="legalInfo || {}"
                                            :uploads="uploads || []"
                                        />
                                    </div>
                                    <div class="col-12 mb-4 d-flex flex-column">
                                        <legal-info-other
                                            class="border rounded p-4 h-100"
                                            :legal-info="legalInfo || {}"
                                        />
                                    </div>
                                    <div class="col-12 mb-4 d-flex flex-column">
                                        <div class="border rounded p-4 h-100">
                                            <h4 class="mb-2">{{ $t('clientSessions.detail.ib.heading') }}</h4>
                                            <detail-item
                                                :title="$t('clientSessions.detail.legalInfo.ibAndVop.ib')"
                                                :content="ib ? $t('app.yes') : $t('app.no')"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab
                                :title="$t('clientSessions.detail.tab.realOwners')"
                                v-if="realOwners && Array.isArray(realOwners) && realOwners.length > 0"
                            >
                                <div class="mt-4">
                                    <h4 class="mb-2">{{$t('clientSessions.detail.realOwner.heading')}}</h4>
                                    <real-owner-table
                                        :dataset="realOwners"
                                        @show="onShowRealOwner"
                                    />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <div class="col-12 col-xl-5">
                    <div class="bg-white shadow p-4 rounded">
                        <export-state-table
                            :dataset="exportStates"
                            :loading="exportStatesLoading"
                        />
                    </div>
                </div>
            </div>
            <real-owner-modal
                :real-owner="realOwner || {}"
                :statutory-types="statutoryTypes"
                :uploads="[]"
            />
        </div>
    </div>
</template>

<script>
import ExportStateTable from '../../../ClientSessions/Views/ClientSessionDetail/Components/ExportStateTable.vue'
import BasicDataClientInfo from '../../../ClientSessions/Views/ClientSessionDetail/Components/BasicDataClientInfo.vue'
import BasicDataContactInfo from '../../../ClientSessions/Views/ClientSessionDetail/Components/BasicDataContactInfo.vue'
import LegalInfoMoneyOrigin from '../../../ClientSessions/Views/ClientSessionDetail/Components/LegalInfoMoneyOrigin.vue'
import LegalInfoCompanyInfo from '../../../ClientSessions/Views/ClientSessionDetail/Components/LegalInfoCompanyInfo.vue'
import LegalInfoReasonAndAmount from '../../../ClientSessions/Views/ClientSessionDetail/Components/LegaInfoReasonAndAmount.vue'
import LegalInfoOther from '../../../ClientSessions/Views/ClientSessionDetail/Components/LegalInfoOther.vue'
import RealOwnerModal from '../../../ClientSessions/Views/ClientSessionDetail/Components/RealOwnerModal.vue'
import RealOwnerTable from '../../../ClientSessions/Views/ClientSessionDetail/Components/RealOwnerTable.vue'
import SessionChangeStateForm from '../../../ClientSessions/Views/ClientSessionDetail/Components/SessionChangeStateForm.vue'

export default {
    name: 'AmlSessionsDetail',
    components: {
        ExportStateTable,
        BasicDataClientInfo,
        BasicDataContactInfo,
        LegalInfoMoneyOrigin,
        LegalInfoCompanyInfo,
        LegalInfoReasonAndAmount,
        LegalInfoOther,
        RealOwnerModal,
        RealOwnerTable,
        SessionChangeStateForm
    },
    props: {
        amlSessionId: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            amlSession: null,
            amlSessionLoading: true,
            realOwner: null,
            exportStatesLoading: false,
            exportStates: [],
            savingState: false,
            statutoryTypes: []
        }
    },
    mounted () {
        this.getAmlSession()
        this.loadExportStates()
        this.getStatutoryTypes()
    },
    computed: {
        sessionState () {
            return this.amlSession ? this.amlSession.state : 'error'
        },
        sessionHasError () {
            return this.sessionState === 'error'
        },
        clientType () {
            return (this.amlSession && this.amlSession.clientType) ? this.amlSession.clientType : null
        },
        basicData () {
            return (this.amlSession && this.amlSession.basicData) ? this.amlSession.basicData : null
        },
        personalId () {
            return (this.amlSession && this.amlSession.personalId) ? this.amlSession.personalId : null
        },
        bankAccounts () {
            return this.amlSession && this.amlSession.bankAccounts
                ? this.amlSession.bankAccounts.map(item => {
                    return {
                        wholeAccountNumber: `${item.accountNumberPrefix}-${item.accountNumber}/${item.bankCode}`,
                        ...item
                    }
                })
                : []
        },
        legalInfo () {
            return (this.amlSession && this.amlSession.legalInfo) ? this.amlSession.legalInfo : null
        },
        ib () {
            return (this.amlSession && this.amlSession.activateIB) ? this.amlSession.activateIB : null
        },
        realOwners () {
            return (this.amlSession && this.amlSession.realOwners) ? this.amlSession.realOwners : []
        }
    },
    methods: {
        async getAmlSession () {
            this.amlSessionLoading = true
            try {
                const response = await this.$api.amlSessions.read(this.amlSessionId)
                this.amlSession = response.data
            } catch (error) {
                console.error(error)
            } finally {
                this.$nextTick(() => {
                    this.amlSessionLoading = false
                })
            }
        },
        getStatutoryTypes () {
            this.$api.enums.read('statutory-types')
                .then(response => {
                    this.statutoryTypes = response.data
                })
                .catch(error => {
                    console.error(error)
                })
        },
        onShowRealOwner (realOwner) {
            this.realOwner = realOwner
            this.$bvModal.show('realOwnerModal')
        },
        async loadExportStates () {
            this.exportStatesLoading = true
            try {
                const response = await this.$api.exportStates.read(null, { amlSessionId: this.amlSessionId, processName: 'UpdateAmlProcess' })
                this.exportStates = response.data
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.cannotLoadExportStates'))
            } finally {
                this.$nextTick(() => {
                    this.exportStatesLoading = false
                })
            }
        },
        onBack () {
            this.$router.push({ name: 'AdminAmlSessions' })
                .catch(() => {})
        },
        onChangeSessionState (data) {
            this.savingState = true
            this.$api.amlSessions.changeState(this.amlSessionId, data)
                .catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('errors.cannotUpdateSessionState'))
                })
                .finally(() => {
                    this.savingState = false
                    this.getAmlSession()
                })
        },
        onSendToIvan () {
            this.$api.amlSessions.exportToIvan(this.amlSessionId)
                .then(response => {
                    const amlSession = response.data
                    if (amlSession.state === 'error') {
                        this.$notify.error(this.$t('clientSessions.sendToIvanError'))
                    }
                })
                .catch(error => {
                    this.$notify.error(this.$t('clientSessions.sendToIvanError'))
                    console.error(error)
                })
                .finally(() => {
                    this.getAmlSession()
                    this.loadExportStates()
                })
        }
    }
}
</script>
